/*
 * Mapplic - Custom Interactive Map Plugin by @sekler
 * Version 3.1
 * http://www.mapplic.com
 */

.mapplic-element a {
	color: #333;
	text-decoration: none !important;
}

.mapplic-element input,
.mapplic-element button {
	outline: none;
}

.mapplic-element * { -webkit-tap-highlight-color: rgba(0,0,0,0); }

.mapplic-element {
	background-color: #fff;
	font-size: 0;
	overflow: hidden;
}

/* Fullscreen */
:-webkit-full-screen {
	width: 100%;
	height: 100% !important;
}

:-webkit-full-screen .mapplic-fullscreen-button {
	background-image: url(images/fullscreen-exit.png);
}

/* Preloader */
.mapplic-element.mapplic-loading {
	background: #f4f4f4 url(images/loader.gif) no-repeat center;
}

.mapplic-element.mapplic-error {
	background: #f4f4f4 url(images/error-icon.png) no-repeat center;
}

.mapplic-element > * {
	opacity: 1;
	-webkit-transition: opacity 0.6s;
	-moz-transition: opacity 0.6s;
	transition: opacity 0.6s;
}

.mapplic-element.mapplic-loading > * {
	opacity: 0;
}

/* Main elements */
.mapplic-container {
	background-color: #f8f8f8;
	display: inline-block;
	overflow: hidden;
	position: relative;
	width: 70%;
	height: 100%;
}

:-webkit-full-screen .mapplic-container {
	width: 80%;
}

.mapplic-map {
	position: absolute;
	left: 0;
	top: 0;
	overflow: visible !important;
}

/* Map layer */
.mapplic-layer {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}

.mapplic-layer img {
	width: 100%;
}

.mapplic-map .mapplic-map-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.mapplic-map.mapplic-zoomable .mapplic-map-image {
	cursor: url(images/openhand.cur), default;
}

.mapplic-map.mapplic-zoomable.mapplic-dragging .mapplic-map-image {
	cursor: url(images/closedhand.cur), move;
}

.mapplic-locations {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.mapplic-pin {
	background-image: url(images/pin.png);
	background-size: 18px 24px;
	background-repeat: no-repeat;
	background-position: center;
	width: 18px;
	height: 24px;
	margin-top: -23px;
	margin-left: -9px;
	position: absolute;
}

.mapplic-pin.iconpin {
	background-image: url(images/pin-large.png);
	background-size: 30px 42px;
	color: #fff;
	font-size: 14px;
	font-weight: normal;
	line-height: 36px;
	text-align: center;
	width: 30px;
	height: 42px;
	margin-top: -42px;
	margin-left: -15px;
}

.mapplic-pin.orange { background-image: url(images/pin-orange.png); }
.mapplic-pin.yellow { background-image: url(images/pin-yellow.png); }
.mapplic-pin.green { background-image: url(images/pin-green.png); }
.mapplic-pin.blue { background-image: url(images/pin-blue.png); }
.mapplic-pin.purple { background-image: url(images/pin-purple.png); }
.mapplic-pin.white { background-image: url(images/pin-white.png); }

.mapplic-pin.iconpin.orange { background-image: url(images/pin-orange-large.png); }
.mapplic-pin.iconpin.yellow { background-image: url(images/pin-yellow-large.png); }
.mapplic-pin.iconpin.green { background-image: url(images/pin-green-large.png); }
.mapplic-pin.iconpin.blue { background-image: url(images/pin-blue-large.png); }
.mapplic-pin.iconpin.purple { background-image: url(images/pin-purple-large.png); }
.mapplic-pin.iconpin.white { background-image: url(images/pin-purple-white.png); }

.mapplic-pin.circular {
	background-image: none;
	background-color: #fb7575;
	border-radius: 6px;
	box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1) inset;
	width: 12px;
	height: 12px;
	margin-left: -6px;
	margin-top: -6px;
}

.mapplic-pin.transparent {
	background-image: none;
	background-color: #fb7575;
	border-radius: 10px;
	width: 20px;
	height: 20px;
	margin-left: -10px;
	margin-top: -10px;
	opacity: 0.5 !important;
}

.mapplic-pin.mapplic-animate {
	-webkit-animation: bounce-in 0.4s forwards;
	animation: bounce-in 0.4s forwards;
}

@-webkit-keyframes bounce-in {
	0%   {margin-top: -120px; opacity: 0;}
	33%  {margin-top: -25px; opacity: 1;}
	66%  {margin-top: -30px;}
	100% {margin-top: -23px; opacity: 1;}
}

/* Minimap */
.mapplic-minimap {
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 2px;
	position: absolute;
	width: 140px;
	margin: 10px;
	bottom: 0;
	right: 0;
	opacity: 0.5;
	overflow: hidden;
	-webkit-transition: opacity 0.6s;
	-moz-transition: opacity 0.6s;
	transition: opacity 0.6s;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.mapplic-minimap img {
	width: 100%;
}

.mapplic-minimap-overlay {
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.mapplic-minimap .mapplic-minimap-active {
	position: absolute;
	opacity: 1;
	top: 0;
	left: 0;
	-webkit-transition: clip 0.1s;
	-moz-transition: clip 0.1s;
	transition: clip 0.1s;
}

.mapplic-minimap-background {
	-webkit-filter: blur(2px);
}

/* Clear Button */
.mapplic-clear-button {
	background-color: #fff;
	background-image: url(images/reset.png);
	background-size: 16px 16px;
	background-repeat: no-repeat;
	background-position: center;
	border: 1px solid #eee;
	border-bottom: 1px solid #ddd;
	border-radius: 3px;
	margin: 10px;
	width: 28px;
	height: 28px;
	position: absolute;
	bottom: 0;
	left: 0;
}

.mapplic-clear-button:active {
	background-color: #eee;
}

/* Zoom Buttons */
.mapplic-zoom-buttons {
	margin: 10px;
	position: absolute;
	left: 0;
	bottom: 40px;
}

.mapplic-zoom-buttons a {
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	border: 1px solid #eee;
	border-bottom: 1px solid #ddd;
	color: #fff !important;
	display: block;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	text-align: center;
	line-height: 24px;
	width: 28px;
	height: 28px;
}

.mapplic-zoom-buttons a:active {
	background-color: #f4f4f4;
}

.mapplic-zoom-buttons a.mapplic-disabled {
	background-color: #eee;
	cursor: default;
}

a.mapplic-zoomin-button {
	background-image: url(images/plus.png);
	background-size: 10px 10px;
	border-radius: 3px 3px 0 0;
}

a.mapplic-zoomout-button {
	background-image: url(images/minus.png);
	background-size: 10px 10px;
	border-radius: 0 0 3px 3px;
	border-top: none;
}

/* Fullscreen Button */
.mapplic-fullscreen-button {
	background-color: #fff;
	background-image: url(images/fullscreen.png);
	background-repeat: no-repeat;
	background-position: center;
	border: 1px solid #eee;
	border-bottom: 1px solid #ddd;
	border-radius: 2px;
	margin: 10px;
	width: 28px;
	height: 28px;
	position: absolute;
	top: 0;
	left: 0;
}

/* Levels */
.mapplic-levels {
	position: absolute;
	top: 0;
	right: 0;
	margin: 10px;
	overflow: hidden;
}

.mapplic-levels > * {
	border: 1px solid #eee;
	display: block;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.mapplic-levels-select {
	background-color: #fff;
	border-top: none;
	color: #666;
	margin: 0;
	padding: 6px 2px;
	font-size: 14px;
	outline: none;
}

.mapplic-levels a {
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	height: 24px;
	width: 100%;
}

.mapplic-levels a:active {
	background-color: #f8f8f8;
}

.mapplic-levels .mapplic-levels-up {
	background-image: url(images/arrow-up.png);
	background-size: 8px 4px;
	border-radius: 3px 3px 0 0;
}

.mapplic-levels .mapplic-levels-down {
	background-image: url(images/arrow-down.png);
	background-size: 8px 4px;
	border-top: none;
	border-radius: 0 0 3px 3px;
}

.mapplic-levels a.mapplic-disabled {
	background-color: #eee;
	cursor: default;
}

/* Sidebar */
.mapplic-sidebar {
	background-color: #f8f8f8;
	width: 30%;
	height: 100%;
	float: left;
	position: relative;
}

:-webkit-full-screen .mapplic-sidebar {
	width: 20%;
}

/* Search */
.mapplic-search-form {
	background-color: #f8f8f8;
	border-bottom: 1px solid #eee;
	border-right: 1px solid #f4f4f4;
	padding: 14px 12px;
	margin: 0;
	width: 100%;
	position: absolute;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.mapplic-search-input {
	background-image: url(images/viewer.png);
	background-size: 17px 16px;
	background-repeat: no-repeat;
	background-position: 8px;
	border: 2px solid #eee;
	border-radius: 2px;
	font-size: 14px;
	font-family: inherit;
	line-height: 20px;
	height: 38px;
	margin: 0;
	padding: 8px 32px;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-transition: border-color 0.1s;
	-moz-transition: border-color 0.1s;
	transition: border-color 0.1s;
}

.mapplic-search-input:focus {
	border-color: #6ed8dd;
}

.mapplic-search-clear {
	background-image: url(images/cross.png);
	background-size: 8px 8px;
	background-repeat: no-repeat;
	background-color: transparent;
	background-position: center;
	border: none;
	cursor: pointer;
	display: none;
	position: absolute;
	top: 14px;
	right: 14px;
	margin: 2px 0;
	width: 34px;
	height: 34px;
}

.mapplic-not-found {
	color: #bbb;
	display: none;
	font-size: 13px;
	padding: 0 30px;
	position: absolute;
	text-align: center;
	top: 100px;
}

/* List */
.mapplic-list-container {
	padding-top: 67px;
	height: 100%;
	overflow-y: auto;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.mapplic-list {
	list-style: none;
	padding: 0;
	margin: 0;
	overflow-y: auto;
	height: 100%;
}

.mapplic-list-container ol {
	border-color: #eee;
	list-style: none;
	padding: 0;
	margin: 0;
}

.mapplic-list-container li {
	border-color: inherit;
}

.mapplic-list-category > a {
	background-color: #888;
	box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.05) inset;
	color: #fff;
	display: block;
	font-size: 14px;
	line-height: 26px;
	padding: 10px 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	box-sizing: border-box;
}

.mapplic-list-category ol {
	border-bottom: 2px solid #eee !important;
}

.mapplic-list-thumbnail {
	border-radius: 2px;
	float: left;
	margin-right: 10px;
}

.mapplic-list-category > a .mapplic-list-count {
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	float: right;
	font-size: 12px;
	font-weight: bold;
	line-height: 20px;
	padding: 0 6px;
	margin-left: 10px;
	text-align: center;
	margin-top: 4px;
	-webkit-transition: border-radius 0.2s;
	-moz-transition: border-radius 0.2s;
	transition: border-radius 0.2s;

}

.mapplic-list-location {
	border-bottom: 1px solid #eee;
	margin: 0;
}

.mapplic-list-location > a {
	background-color: #fff;
	border-left: 1px solid transparent;
	display: block;
	font-size: 14px;
	padding: 10px;
	min-height: 50px;
	-webkit-transition: border 0.2s;
	-moz-transition: border 0.2s;
	transition: border 0.2s;
}

.mapplic-list-location > a:hover {
	background-color: #f4fcfc;
	border-left: 2px solid;
	border-color: inherit;
}

.mapplic-list-location h4 {
	color: #444;
	font-size: 16px;
	font-weight: normal;
	margin: 4px 0 8px 0;
	text-align: left;
}

.mapplic-list-location span { /* 42 -20 13 30*/
	color: #bbb;
	font-size: 13px;
	font-weight: normal;
}

/* Tooltip */
.mapplic-tooltip {
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
	display: none;
	max-width: 260px;
	min-width: 120px;
	margin-top: -76px;
	padding: 16px;
	position: absolute;
	-webkit-transition: margin 0.1s;
	-moz-transition: margin 0.1s;
	transition: margin 0.1s;
}

.mapplic-tooltip-title {
	color: #333;
	font-size: 20px;
	font-weight: normal;
	margin: 0 30px 12px 0;
}

.mapplic-hovertip {
	min-width: 30px;
	padding: 6px 14px;
	pointer-events: none;
}

.mapplic-hovertip .mapplic-tooltip-title {
	margin: 0;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
}


.mapplic-bottom .mapplic-tooltip-triangle {
	border-color: transparent transparent #fff transparent;
	border-width: 0 7px 8px 7px;
	top: 0;
	margin-top: -8px;
}

.mapplic-tooltip-content {
	max-height: 160px;
	overflow-y: auto;
}

.mapplic-tooltip-content p {
	margin-top: 0;
}

.mapplic-tooltip-image {
	width: 46%;
	height: 100%;
	/*margin: 5px 20px 5px 0;*/
	margin: -16px 16px -16px -16px;
	float: left;
}

.mapplic-tooltip-description,
.mapplic-tooltip p {
	color: #aaa;
	font-size: 13px;
	line-height: 20px;
}

.mapplic-tooltip-link {
	background-color: #6CB5F4;
	border-radius: 2px;
	color: #fff !important;
	float: right;
	font-size: 14px;
	line-height: 32px;
	padding: 0 12px;
	margin-top: 10px;
	-webkit-transition: background-color 0.2s;
	-moz-transition: background-color 0.2s;
	transition: background-color 0.2s;
}

.mapplic-tooltip-link:hover {
	background-color: #888;
}

.mapplic-tooltip img {
	max-width: 100%;
}

.mapplic-tooltip-close {
	background-image: url(images/cross.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 8px 8px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	float: right;
	margin: -10px -14px 0 0;
	padding: 10px 12px;
	width: 10px;
	height: 10px;
	opacity: 0.5;
	-webkit-transition: opacity 0.2s;
	-moz-transition: opacity 0.2s;
	transition: opacity 0.2s;
}

.mapplic-tooltip-close:hover {
	opacity: 1.0;
}

.mapplic-tooltip-triangle {
	border-color: #fff transparent transparent transparent;
	border-style: solid;
	border-width: 8px 7px 0 7px;
	width: 0;
	height: 0;
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-bottom: -8px;
	margin-left: -7px;
	-webkit-transition: left 0.1s;
	-moz-transition: left 0.1s;
	transition: left 0.1s;
}

/* Tooltip down */
.mapplic-tooltip-down .mapplic-tooltip-triangle {
	border-width: 0 7px 8px 7px;
	border-color: transparent transparent #fff transparent;
	top: 0;
	margin-top: -8px;
}

/* Coordinates */
.mapplic-coordinates {
	background-color: rgba(255, 255, 255, 0.9);
	color: #333;
	position: absolute;
	margin: 10px;
	margin-left: -80px;
	padding: 4px 6px;
	font-size: 14px;
	top: 0;
	left: 50%;
	pointer-events: none;
}

/* Responsive layout */
@media all and (max-width: 667px) {
	.mapplic-container,
	.mapplic-sidebar {
		width: 100%;
	}

	.mapplic-tooltip {
		max-width: 240px;
	}

	.mapplic-minimap {
		width: 120px;
	}

	.mapplic-element {
		height: auto !important;
	}

	.mapplic-fullscreen-button {
		display: none;
	}

	.mapplic-search-form {
		border-right: none;
	}
}

/* RETINA */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
	.mapplic-search-clear,
	.mapplic-tooltip-close {
		background-image: url(images/cross@2x.png);
	}

	.mapplic-levels .mapplic-levels-up {
		background-image: url(images/arrow-up@2x.png);
	}

	.mapplic-levels .mapplic-levels-down {
		background-image: url(images/arrow-down@2x.png);
	}

	a.mapplic-zoomin-button {
		background-image: url(images/plus@2x.png);
	}

	a.mapplic-zoomout-button {
		background-image: url(images/minus@2x.png);
	}

	.mapplic-search-input {
		background-image: url(images/viewer@2x.png);
	}

	.mapplic-pin { background-image: url(images/pin@2x.png); }
	.mapplic-pin.orange { background-image: url(images/pin-orange@2x.png); }
	.mapplic-pin.yellow { background-image: url(images/pin-yellow@2x.png); }
	.mapplic-pin.green { background-image: url(images/pin-green@2x.png); }
	.mapplic-pin.blue { background-image: url(images/pin-blue@2x.png); }
	.mapplic-pin.purple { background-image: url(images/pin-purple@2x.png); }
	.mapplic-pin.white { background-image: url(images/pin-white@2x.png); }

	.mapplic-pin.iconpin { background-image: url(images/pin-large@2x.png); }
	.mapplic-pin.iconpin.orange { background-image: url(images/pin-orange-large@2x.png); }
	.mapplic-pin.iconpin.yellow { background-image: url(images/pin-yellow-large@2x.png); }
	.mapplic-pin.iconpin.green { background-image: url(images/pin-green-large@2x.png); }
	.mapplic-pin.iconpin.blue { background-image: url(images/pin-blue-large@2x.png); }
	.mapplic-pin.iconpin.purple { background-image: url(images/pin-purple-large@2x.png); }
	.mapplic-pin.iconpin.white { background-image: url(images/pin-white-large@2x.png); }

	.mapplic-clear-button {
		background-image: url(images/reset@2x.png);
	}
}

/* Map */
.mapplic-element svg {
	width: 100%;
	height: 100%;
}

.mapplic-element svg a {
	cursor: pointer;
}

.mapplic-active,
a.mapplic-active > path,
g.mapplic-active > * {
	fill: #343F4B;
	opacity: 1.0;
}

.mapplic-clickable:not(g),
g.mapplic-clickable > * {
	cursor: pointer;
	opacity: 0.4;
	-webkit-transition: opacity 0.2s;
	-moz-transition: opacity 0.2s;
	transition: opacity 0.2s;
}

.mapplic-clickable:not(g):hover,
g.mapplic-clickable:hover > * {
	opacity: 0.8;
}

.mapplic-map-image *[id^=nopointer] {
	pointer-events: none;
}

[id^=landmarks] .mapplic-clickable {
	cursor: pointer;
}

/* Default color - Disable this if you want to use the colors from your SVG file! */
/*
.mapplic-clickable,
g.mapplic-clickable > * {
	fill: #4d5e6d;
}
*/
/* here you can put your own css to customize and override the theme */

/***
Rounded Portlets
***/
/*
.portlet {
	border-radius: 4px !important;
}

.portlet .portlet-title {
	border-radius: 4px 4px 0px 0px !important;
}

.portlet .portlet-body,
.portlet .portlet-body .form-actions  {
	border-radius: 0px 0px 4px 4px !important;
}
*/
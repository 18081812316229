/*--------------------------------------------------
    [TRANSITION]
----------------------------------------------------*/
/* Cubic Bezier Transition */
/*--------------------------------------------------
    [RGBA Opacity]
----------------------------------------------------*/
@media print {
  body {
    background-color: #fff !important;
  }

  .page-bar {
    display: none;
  }

  .page-sidebar-wrapper {
    display: none;
  }

  .theme-panel {
    display: none;
  }

  .hidden-print {
    display: none;
  }

  .page-footer {
    display: none;
  }

  .no-page-break {
    page-break-after: avoid;
  }

  .page-container {
    margin: 0px !important;
    padding: 0px !important;
  }

  .page-content {
    padding: 0 !important;
    min-height: 300px !important;
    padding: 0px 20px 20px !important;
    margin: 0 !important;
  }
  .page-content > .portlet {
    padding: 0;
    margin: 0;
  }
  .page-content > .portlet > .portlet-body {
    padding: 0;
    margin: 0;
  }
}
/***
Page Header
***/
.page-header.navbar {
  width: 100%;
  padding: 0 20px 0 20px;
  margin: 0;
  border: 0px;
  padding: 0px;
  box-shadow: none;
  height: 68px;
  min-height: 68px;
  filter: none;
  background-image: none;
  /* Fixed header */
  /* Header logo */
  /* header top */
  /* Search box */
  /* Menu Toggler */
  /* Top menu */
}
.page-header.navbar.navbar-fixed-top {
  z-index: 9995;
}
.page-header.navbar.navbar-static-top {
  z-index: 9995;
}
.page-header.navbar .page-logo {
  float: left;
  display: block;
  width: 195px;
  height: 68px;
  padding-left: 20px;
  padding-right: 20px;
}
.page-header.navbar .page-logo > .logo-image,
.page-header.navbar .page-logo > a {
  display: block;
  float: left;
}
.page-header.navbar .page-logo .logo-default {
  margin: 27px 0 0 0;
}
.page-header.navbar .page-logo .logo-mini {
  display: none;
  margin-left: 5px;
}
.page-header.navbar .page-logo .text-logo {
  padding-left: 20px;
  padding-top: 12px;
}
.page-header.navbar .page-top {
  height: 68px;
  background: #BAC0B6;
}
.page-header.navbar .search-form {
  margin-left: 10px;
  display: inline-block;
  width: 68px;
  position: relative;
  float: left !important;
  transition: all 0.6s;
}
.page-header.navbar .search-form .input-group .form-control {
  height: 68px;
  border: 0;
  background: transparent !important;
  font-size: 14px;
  padding-left: 0;
  margin-left: 12px;
  text-indent: -150000px;
}
.page-header.navbar .search-form .input-group .form-control:hover {
  cursor: pointer;
}
.page-header.navbar .search-form .input-group .input-group-btn {
  height: 68px;
}
.page-header.navbar .search-form .input-group .input-group-btn .btn.submit {
  margin-left: -24px;
  padding: 0;
  width: 68px;
  background: none;
  margin-top: 4px;
  display: block;
}
.page-header.navbar .search-form .input-group .input-group-btn .btn.submit > i {
  font-size: 15px;
}
.page-header.navbar .search-form.open {
  transition: all 0.6s;
  width: 300px !important;
}
.page-header.navbar .search-form.open .input-group .form-control {
  text-indent: 0;
}
.page-header.navbar .search-form.open .input-group .form-control:hover {
  cursor: text;
}
.page-header.navbar .search-form.open .input-group .input-group-btn .btn.submit {
  margin-left: 0;
}
.page-header.navbar .menu-toggler {
  display: block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../img/sidebar-toggler.png);
}
.page-header.navbar .menu-toggler:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}
.page-header.navbar .menu-toggler.sidebar-toggler {
  float: right;
  margin: 24px 0 0 0;
}
.page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .menu-toggler.sidebar-toggler {
  margin-right: 17px;
}
.page-header.navbar .menu-toggler.responsive-toggler {
  display: none;
  float: right;
  margin: 24px 14px 0 6px;
  background-image: url(../img/sidebar-toggler-inverse.png);
}
.page-header.navbar .page-actions {
  margin: 17px 0 15px 15px;
  padding: 0;
  float: left;
}
.page-header.navbar .page-actions .btn-group .dropdown-menu:before {
  left: 9px;
  right: auto;
}
.page-header.navbar .page-actions .btn-group .dropdown-menu:after {
  left: 10px;
  right: auto;
}
.page-header.navbar .top-menu {
  margin: 0;
  padding: 0;
  float: right;
}
.page-header.navbar .top-menu .navbar-nav {
  padding: 0;
  margin-right: 20px;
  display: block;
  /* Extended Dropdowns */
  /* Notification */
  /* Inbox */
  /* Tasks */
  /* User */
  /* Quick Sidebar */
  /* Language */
  /* Dark version */
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown {
  margin: 0px;
  padding: 0px;
  height: 68px;
  display: inline-block;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle {
  margin: 0px;
  padding: 28px 16px 19px 16px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:last-child {
  padding-right: 0;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle > i {
  font-size: 19px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle > i.glyphicon {
  top: 0;
  font-size: 17px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle > .badge {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 17px;
  right: 9px;
  font-weight: 300;
  padding: 3px 6px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:focus {
  background: none;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu {
  margin-top: 9px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #eee;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block !important;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu > li > a {
  color: #555;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu {
  min-width: 160px;
  max-width: 275px;
  width: 275px;
  z-index: 9995;
  /* header notifications dropdowns */
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external {
  display: block;
  overflow: hidden;
  padding: 15px 15px;
  letter-spacing: 0.5px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > h3 {
  margin: 0;
  padding: 0;
  float: left;
  font-size: 13px;
  display: inline-block;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a {
  display: inline-block;
  padding: 0;
  background: none;
  clear: inherit;
  font-size: 13px;
  font-weight: 300;
  position: absolute;
  right: 10px;
  border: 0;
  margin-top: -1px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a:hover {
  text-decoration: underline;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list {
  padding-right: 0 !important;
  padding-left: 0;
  list-style: none;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a {
  display: block;
  clear: both;
  font-weight: 300;
  line-height: 20px;
  white-space: normal;
  font-size: 13px;
  padding: 16px 15px 18px;
  text-shadow: none;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a:hover {
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li:first-child a {
  border-top: none;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details {
  overflow: hidden;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details .label-icon {
  margin-right: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details .label-icon i {
  margin-right: 2px;
  margin-left: 1px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details .label-icon .badge {
  right: 15px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .time {
  float: right;
  max-width: 75px;
  font-size: 11px;
  font-weight: 400;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
  text-align: right;
  padding: 1px 5px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .photo {
  float: left;
  margin: 0 6px 6px 0;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .photo img {
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  border-radius: 50% !important;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .subject {
  display: block;
  margin-left: 46px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .subject .from {
  font-size: 13px;
  font-weight: 600;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .subject .time {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5 ;
  filter: alpha(opacity=50) ;
  float: right;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .message {
  display: block !important;
  font-size: 12px;
  line-height: 1.3;
  margin-left: 46px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .task {
  margin-bottom: 5px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .task .desc {
  font-size: 13px;
  font-weight: 300;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .task .percent {
  float: right;
  font-weight: 600;
  display: inline-block;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .progress {
  display: block;
  height: 8px;
  margin: 8px 0 2px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .progress .progress-bar {
  box-shadow: none;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user {
  padding: 0 0 0 10px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
  padding: 21px 12px 21px 12px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > .username {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > img {
  float: left;
  margin-top: -8px;
  margin-right: 7px;
  height: 39px;
  display: inline-block;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > i {
  display: inline-block;
  margin-top: 5px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu {
  width: 175px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu > li > a {
  font-size: 14px;
  font-weight: 300;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu > li > a i {
  width: 15px;
  display: inline-block;
  margin-right: 9px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu > li > a .badge {
  margin-right: 10px;
}
.page-header.navbar .top-menu .navbar-nav > li.quick-sidebar-toggler {
  cursor: pointer;
  padding: 24px 12px 24px 12px;
}
.page-header.navbar .top-menu .navbar-nav > li.quick-sidebar-toggler > i {
  top: 3px;
  color: #c0cddc;
  font-size: 19px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-language {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle {
  font-size: 13px;
  padding: 24px 12px 24px 12px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > img {
  margin-bottom: 2px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > i {
  font-size: 14px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-menu > li > a {
  font-size: 13px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-menu > li > a > img {
  margin-bottom: 2px;
  margin-right: 5px;
}
.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu {
  border: 0;
}
.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu:before {
  border-left: none;
  border-right: none;
}
.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu .dropdown-menu-list > li.external a {
  background: none !important;
  border: none !important;
}

/* Allow expanded search for above 768px */
@media (min-width: 768px) {
  /* 768px */
  .page-header.navbar {
    /* Search box */
  }
  .page-header.navbar .search-form.search-form-expanded {
    width: 200px;
  }
  .page-header.navbar .search-form.search-form-expanded .input-group .form-control {
    text-indent: 0;
  }
  .page-header.navbar .search-form.search-form-expanded .input-group .form-control:hover {
    cursor: text;
  }
  .page-header.navbar .search-form.search-form-expanded .input-group .input-group-btn .btn.submit {
    margin-left: 0;
  }
}
/* Form medium devices upto large devices */
@media (min-width: 992px) and (max-width: 1200px) {
  /* 992px 1200px */
  /* Boxed layout */
  .page-boxed .page-header.navbar {
    /* Top menu */
  }
  .page-boxed .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle .username.username-hide-on-mobile {
    display: none;
  }
  .page-boxed .page-header.navbar .top-menu .navbar-nav > li.dropdown-language .dropdown-toggle .langname {
    display: none;
  }
}
@media (min-width: 992px) {
  /* 992px */
  /* Page header */
  .page-header.navbar {
    /* Header logo */
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo {
    padding: 0;
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo {
    width: 54px;
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo .logo-default {
    display: none;
  }

  /* Boxed Layout */
  .page-boxed .page-header.navbar {
    /* Page logo */
    /* Top menu */
  }
  .page-boxed .page-header.navbar .page-logo {
    width: 195px;
  }
  .page-boxed .page-header.navbar .top-menu .navbar-nav {
    margin-right: 20px;
  }

  /* Sidebar closed & logo hidden */
  .page-sidebar-closed.page-sidebar-closed-hide-logo.page-boxed .page-header.navbar {
    /* Page logo */
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo.page-boxed .page-header.navbar .page-logo {
    width: 54px;
  }

  /* Boxed layout & page sidebar fixed layout */
  .page-boxed.page-sidebar-fixed .page-header.navbar {
    /* Page logo */
  }
  .page-boxed.page-sidebar-fixed .page-header.navbar .page-logo {
    width: 195px;
  }
}
@media (max-width: 991px) {
  /* 991px */
  /* Page header */
  .page-header.navbar {
    padding: 0;
    margin: 0;
    position: relative;
    clear: both;
    /* Page logo */
    /* Menu Toggler */
    /* Search form */
    /* Top Menu */
  }
  .page-header.navbar .page-logo {
    width: auto;
    padding: 0 15px 0 10px;
  }
  .page-header.navbar .page-logo img {
    margin-left: 4px !important;
  }
  .page-header.navbar .menu-toggler.sidebar-toggler {
    display: none !important;
  }
  .page-header.navbar .menu-toggler.responsive-toggler {
    display: inline-block;
  }
  .page-header.navbar .search-form {
    margin-left: 0;
  }
  .page-header.navbar .search-form.open {
    width: 245px !important;
  }
  .page-header.navbar .page-header-inner.container {
    width: 100%;
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .page-header.navbar .top-menu .navbar-nav {
    display: inline-block;
    margin: 0 10px 0 0;
  }
  .page-header.navbar .top-menu .navbar-nav > li {
    float: left;
  }
  .page-header.navbar .top-menu .navbar-nav .nav li.dropdown i {
    display: inline-block;
    position: relative;
    top: 1px;
    right: 0px;
  }
  .page-header.navbar .top-menu .navbar-nav .open .dropdown-menu {
    position: absolute;
  }

  /* Fixed header for mobile */
  .page-header-fixed.page-header-fixed-mobile .navbar-fixed-top {
    position: fixed;
  }

  /* Boxed Layout */
  .page-boxed .page-header.navbar > .container {
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* 768px & 991px */
  /* Boxed Layout */
  .page-boxed .page-header.navbar {
    margin: auto !important;
    padding: 0;
  }
  .page-boxed .page-header.navbar > .container {
    margin: auto !important;
  }
}
@media (max-width: 767px) {
  /* 767px */
  /* Page header */
  .page-header.navbar {
    padding: 0;
    /* Header logo */
    /* Search box */
    /* Top navigation menu*/
  }
  .page-header.navbar .page-logo {
    width: auto;
  }
  .page-header.navbar .search-form.open {
    z-index: 3;
    left: 10px;
    right: 10px;
    position: absolute;
    width: auto !important;
    margin: 0 -10px !important;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended > .dropdown-menu {
    max-width: 245px;
    width: 245px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu {
    margin-right: -170px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu:after, .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu:before {
    margin-right: 170px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu {
    margin-right: -120px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu:after, .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu:before {
    margin-right: 120px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu {
    margin-right: -80px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu:after, .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu:before {
    margin-right: 80px;
  }
}
@media (max-width: 580px) {
  /* Page header */
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle .username.username-hide-on-mobile {
    display: none;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-language .dropdown-toggle .langname {
    display: none;
  }
}
@media (max-width: 767px) {
  /* 767px */
  .page-header.navbar {
    height: 136px;
    /* Top menu */
  }
  .page-header.navbar .top-menu .navbar-nav {
    margin-right: 0;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle {
    padding: 28px 10px 19px 10px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-language .dropdown-toggle {
    padding: 24px 6px 24px 6px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-user {
    margin-right: 10px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
    padding: 24px 6px 24px 6px;
  }
  .page-header.navbar .menu-toggler.responsive-toggler {
    background-image: url(../img/sidebar-toggler.png);
  }
  .page-header.navbar .search-form {
    margin-left: -12px;
  }
  .page-header.navbar .page-top {
    display: block;
    clear: both;
  }
}
/***
Pace - Page Progress
***/
.pace .pace-progress {
  z-index: 10000;
  top: 66px;
  height: 2px;
}

.pace .pace-progress-inner {
  box-shadow: none;
}

.pace .pace-activity {
  top: 70px;
  right: 22px;
  border-radius: 10px !important;
}

@media (max-width: 767px) {
  /* 767px */
  .page-header-fixed .pace .pace-progress {
    top: 136px;
  }

  .page-header-fixed .pace .pace-activity {
    top: 276px;
    right: 15px;
  }
}
/***
Page container
***/
.page-container {
  margin: 0px;
  padding: 0px;
  position: relative;
  /* Fixed header */
  /* Fixed footer for mobile */
}
.page-container:before, .page-container:after {
  content: " ";
  display: table;
}
.page-container:after {
  clear: both;
}
.page-header-fixed .page-container {
  margin-top: 68px;
}
.page-footer-fixed.page-footer-fixed-mobile .page-container {
  margin-bottom: 20px !important;
}

@media (min-width: 1260px) {
  /* 1200px */
  .container {
    width: 1270px;
  }
}
@media (min-width: 992px) {
  /* Page container in fixed footer */
  .page-footer-fixed .page-container {
    margin-bottom: 20px !important;
  }
}
@media (max-width: 991px) {
  /* Page container */
  .page-container {
    margin: 0 !important;
    padding: 0 !important;
  }
  .page-header-fixed.page-header-fixed-mobile .page-container {
    margin-top: 68px !important;
  }
}
@media (max-width: 480px) {
  /* Page container */
  .page-header-fixed.page-header-fixed-mobile .page-container {
    margin-top: 136px !important;
  }
}
/******************
Page Quick Sidebar
******************/
/***
Quick Sidebar Layout
***/
/* Page Portlet Fullscreen */
.page-portlet-fullscreen .page-quick-sidebar-wrapper,
.page-portlet-fullscreen .page-quick-sidebar-toggler {
  z-index: -1;
}

/* Quick sidebar toggler */
.page-header .mega-menu .quick-sidebar-toggler {
  top: 7px;
  border: none;
  outline: none;
  padding: 5px 5px;
  margin-left: 15px;
  position: relative;
  background: #45b6af;
}
.page-header .mega-menu .quick-sidebar-toggler i {
  top: 0;
  color: #fff;
}
.page-quick-sidebar-open .page-header .mega-menu .quick-sidebar-toggler i:before {
  content: "\e066";
}

/* Quick sidebar wrapper */
.page-quick-sidebar-wrapper {
  transition: right 0.3s;
  z-index: 9996;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -320px;
  width: 320px;
}
.page-quick-sidebar-open .page-quick-sidebar-wrapper {
  transition: right 0.3s;
  right: 0;
}

/*** 
Quick Sidebar Toggler 
***/
.page-quick-sidebar-toggler {
  overflow: hidden;
  z-index: 99999;
  display: none;
  width: 28px;
  height: 27px;
  position: fixed;
  top: 5px;
  right: 5px;
  text-align: center;
  padding-top: 6px;
  background: inherit !important;
}
.page-quick-sidebar-open .page-quick-sidebar-toggler {
  display: inline-block;
}
.page-quick-sidebar-toggler > i {
  font-size: 17px;
}
.page-quick-sidebar-toggler > i:hover {
  color: #fff !important;
}

/***
Quick Sidebar Content
***/
.page-quick-sidebar-wrapper {
  right: -320px;
  overflow: hidden;
}
.page-quick-sidebar-wrapper .page-quick-sidebar {
  /* Quick sidebar tabs */
  /* Quick sidebar tabs content */
  /* Quick sidebar general list heading */
  /* Quick sidebar general list-items */
  /* Inner content */
  /* Quick sidebar list */
  /* Quick sidebar list item */
  /* Quick sidebar list item shown */
  /* Quick sidebar chat */
  /* Quick sidebar alerts */
  /* Quick sidebar settings */
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified {
  margin: 0;
  padding: 0;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li {
  display: table-cell !important;
  width: 1%  !important;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li > a {
  border: 0;
  height: auto;
  font-size: 15px;
  padding: 45px 15px 8px;
  text-transform: uppercase;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li > a > .badge {
  position: absolute;
  top: 45px;
  right: 3px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li > a:hover {
  border: 0;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu {
  margin-top: 8px;
  margin-right: 20px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 19px;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 20px;
  display: inline-block !important;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > .tab-content {
  margin: 0;
  padding: 0;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .list-heading {
  font-size: 16px;
  margin: 10px 10px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .list-items > li {
  margin: 0;
  padding: 15px;
  background: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .list-items > li:last-child {
  border-bottom: 0;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .list-items.borderless li {
  border: 0;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .inner-content {
  margin: 10px 10px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-list {
  position: absolute !important;
  width: 320px !important;
  transition: margin 0.3s;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item {
  width: 320px;
  position: absolute !important;
  width: 320px !important;
  transition: margin 0.3s;
  margin-left: 320px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav {
  padding: 15px 10px 0px 10px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list {
  vertical-align: middle;
  display: inline-block;
  font-size: 14px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list > i {
  font-size: 17px;
  line-height: 17px;
  vertical-align: top;
  margin-right: 3px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list {
  transition: margin 0.3s;
  margin-left: -320px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list .slimScrollBar,
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list .slimScrollRail {
  display: none !important;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-item {
  transition: margin 0.3s;
  margin-left: 0;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users {
  padding: 10px 0;
  position: relative;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media {
  padding: 15px 15px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object {
  border-radius: 50% !important;
  width: 45.71429px;
  opacity: 0.8;
  filter: alpha(opacity=80);
  float: left;
  margin-right: 10px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:before, .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:after {
  content: " ";
  display: table;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:after {
  clear: both;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media:hover {
  cursor: pointer;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media:hover .media-object {
  opacity: 1;
  filter: alpha(opacity=100);
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading {
  margin: 5px 0 0 0;
  font-size: 14px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-sub {
  font-size: 11px;
  text-transform: uppercase;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-small {
  font-size: 10px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-status {
  margin-top: 10px;
  right: 10px;
  position: absolute;
  display: inline-block;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages {
  padding: 0px 10px;
  position: relative;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post {
  transition: display 0.3s;
  padding: 5px 0;
  margin: 10px auto;
  font-size: 13px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .body {
  color: #c3c3c3;
  display: block;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .avatar {
  width: 45.71429px;
  border-radius: 50% !important;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .avatar {
  float: left;
  margin-right: 10px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .avatar {
  float: right;
  margin-left: 10px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .name {
  font-size: 12px;
  font-weight: 300;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .datetime {
  font-size: 12px;
  font-weight: 300;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .message {
  display: block;
  padding: 5px;
  position: relative;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .message {
  text-align: left;
  margin-left: 55px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .message .arrow {
  display: block;
  position: absolute;
  top: 9px;
  left: -6px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right-width: 6px;
  border-right-style: solid;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .message {
  margin-right: 55px;
  text-align: right;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .message .arrow {
  display: block;
  position: absolute;
  top: 9px;
  right: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left-width: 6px;
  border-left-style: solid;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .name,
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .datetime {
  text-align: right;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-form {
  padding: 20px 10px 15px 10px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-form .input-group .form-control {
  font-size: 13px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list {
  padding: 10px 0;
  position: relative;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li .label {
  margin-top: 5px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li .desc {
  padding: 0;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list {
  padding: 10px 0;
  position: relative;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li .bootstrap-switch {
  margin-top: -3px;
  float: right;
  border: 0;
  min-width: 59px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li .form-control {
  width: 75px !important;
  padding: 4px 4px !important;
  float: right;
  border: 0;
  margin-top: -4px;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li select.form-control {
  padding: 4px 0px !important;
}

/***
Quick Sidebar Layout
***/
.page-quick-sidebar-wrapper {
  background: #21282e;
}
.page-quick-sidebar-wrapper .page-quick-sidebar {
  background: #21282e;
}

/*** 
Quick Sidebar Toggler 
***/
.page-quick-sidebar-toggler {
  background: #36424c;
}
.page-quick-sidebar-toggler:hover {
  background: #303a43;
}
.page-quick-sidebar-toggler > i {
  color: #99a8b5;
}

/***
Quick Sidebar Content
***/
.page-quick-sidebar-wrapper {
  color: #99a8b5;
  /* Quick sidebar chat */
  /* Quick sidebar alerts */
}
.page-quick-sidebar-wrapper .page-quick-sidebar {
  /* Quick sidebar tabs */
  /* Quick sidebar general list heading */
  /* Quick sidebar general list-items */
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li > a {
  background: inherit;
  color: #90a1af;
  border-bottom: 3px solid rgba(243, 86, 93, 0.3);
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li > a:hover {
  border-bottom: 3px solid #f3565d;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li.open > a {
  background: inherit;
  color: #90a1af;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li.active > a {
  border: 0;
  border-bottom: 3px solid #f3565d;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu {
  border: 0;
  background: #36424c;
  box-shadow: 5px 5px rgba(97, 117, 135, 0.1);
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu:before {
  border-bottom: 7px solid #36424c;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu:after {
  border-bottom: 7px solid #36424c;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu > li > a {
  padding: 10px 15px;
  color: #99a8b5;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu > li > a > i {
  color: #93a3b1;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu > li > a:hover {
  background: #3d4a55;
  color: #99a8b5;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu > li > a:hover > i {
  color: #9babb8;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu > li.active > a {
  background: #38444f;
  color: #99a8b5;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-justified > li .dropdown-menu > li.divider {
  background-color: #3d4a55;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .list-heading {
  color: #6c8296;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .list-items > li {
  border-bottom-color: #273037;
}
.page-quick-sidebar-wrapper .page-quick-sidebar .list-items > li:hover {
  background: #273037;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-item {
  /* back to list */
}
.page-quick-sidebar-wrapper .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list {
  color: #90a1af;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list:hover {
  text-decoration: none;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-sub {
  color: #657b8d;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-small {
  color: #5d7081;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .name {
  color: #8496a7;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .datetime {
  color: #8496a7;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .message {
  color: #90a1af;
  background: #36424c;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .message .arrow {
  border-right-color: #36424c;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .message .arrow {
  border-left-color: #36424c;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a {
  color: #7e91a2;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a .desc {
  text-decoration: underline;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li .desc {
  color: #788c9e;
}
.page-quick-sidebar-wrapper .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li .date {
  color: #5d7081;
}

/***
Page sidebar
***/
/* IE8 fix */
.ie8 .page-sidebar {
  width: 195px;
  float: left;
  position: relative;
  margin-right: -100%;
}

/* Page Sidebar */
.page-sidebar,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
  /* Default sidebar menu */
  /* Compact sidebar menu */
  /* Compact hoverable sidebar menu */
}
.page-sidebar.navbar-collapse,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover.navbar-collapse {
  padding: 0;
  box-shadow: none;
}
.page-sidebar .page-sidebar-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  /* 1st level links */
  /* all links */
}
.page-sidebar .page-sidebar-menu > li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li {
  display: block;
  margin: 0;
  padding: 0;
  border: 0px;
}
.page-sidebar .page-sidebar-menu > li.start > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.start > a {
  border-top-color: transparent !important;
}
.page-sidebar .page-sidebar-menu > li.last > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.last > a {
  border-bottom-color: transparent !important;
}
.page-sidebar .page-sidebar-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
  min-height: 78px;
  display: block;
  position: relative;
  margin: 0;
  border: 0px;
  padding: 17px 15px 15px 15px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
}
.page-sidebar .page-sidebar-menu > li > a > .title,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .title {
  display: block;
  text-align: center;
  margin-top: 5px;
}
.page-sidebar .page-sidebar-menu > li > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i {
  font-size: 24px;
  text-shadow: none;
  font-weight: 300;
  text-align: center;
}
.page-sidebar .page-sidebar-menu > li > a > i.glyphicon,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i.glyphicon {
  top: 3px;
  margin-left: 1px;
  margin-right: 4px;
}
.page-sidebar .page-sidebar-menu > li > a > [class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > [class^="icon-"] {
  top: 2px;
  margin-left: 1px;
  margin-right: 4px;
}
.page-sidebar-fixed .page-sidebar .page-sidebar-menu > li > a, .page-sidebar-fixed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
  transition: all 0.2s ease;
}
.page-sidebar-reversed.page-sidebar-fixed .page-sidebar .page-sidebar-menu > li > a, .page-sidebar-reversed.page-sidebar-fixed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
  transition: none;
}
.page-sidebar .page-sidebar-menu > li.open > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a {
  font-size: 13px;
}
.page-sidebar .page-sidebar-menu > li.active > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a {
  border: none;
  text-shadow: none;
  font-size: 13px;
}
.page-sidebar .page-sidebar-menu > li.active > a > .selected,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  background-image: none;
  /* will be set in a theme css file*/
  float: right;
  position: absolute;
  right: -1px;
  top: 26px;
  background: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 12px double transparent;
  border-bottom: 12px double transparent;
  border-left: 0;
  border-right: 8px solid #ffffff;
}
.page-sidebar-reversed .page-sidebar .page-sidebar-menu > li.active > a > .selected, .page-sidebar-reversed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  right: auto;
  left: -1px;
  border-right: 0;
  border-left: 8px solid #ffffff;
}
.page-container-bg-solid .page-sidebar .page-sidebar-menu > li.active > a > .selected, .page-container-bg-solid
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  border-color: transparent #F1F3FA transparent transparent;
}
.page-container-bg-solid.page-sidebar-reversed .page-sidebar .page-sidebar-menu > li.active > a > .selected, .page-container-bg-solid.page-sidebar-reversed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  border-color: transparent transparent transparent #F1F3FA;
}
.page-sidebar .page-sidebar-menu li > a > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow:before {
  width: 10px;
  float: right;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: -32px;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: "\f104";
  font-weight: 300;
  text-shadow: none;
}
.page-sidebar .page-sidebar-menu li > a > .arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow.open:before {
  content: "\f107";
}
.page-sidebar .page-sidebar-menu li > a > .badge,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .badge {
  float: right;
  margin-top: 1px;
  margin-right: 0px;
}
.page-sidebar .page-sidebar-menu .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu {
  list-style: none;
  display: none;
  padding: 0;
  margin: 8px 0px 8px 0px;
}
.page-sidebar .page-sidebar-menu .sub-menu li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li {
  background: none;
  margin: 0px;
  padding: 0px;
  margin-top: 1px !important;
  /* 2nd level sub menu */
}
.page-sidebar .page-sidebar-menu .sub-menu li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a {
  display: block;
  margin: 0;
  padding: 9px 15px 9px 15px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 300;
  background: none;
}
.page-sidebar .page-sidebar-menu .sub-menu li > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a > i {
  font-size: 14px;
  margin-right: 1px;
}
.page-sidebar .page-sidebar-menu .sub-menu li > a .arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a .arrow:before {
  margin-top: -4px;
}
.page-sidebar .page-sidebar-menu .sub-menu li > a .arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a .arrow.open:before {
  margin-top: -2px;
}
.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu {
  margin: 0;
}
.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li {
  /* 3rd level sub menu */
}
.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > a {
  padding-left: 30px;
}
.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu {
  margin: 0;
}
.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu > li > a {
  padding-left: 40px;
}
.page-sidebar .page-sidebar-menu li.active > .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > .sub-menu {
  display: block;
}
.page-sidebar .page-sidebar-menu.page-sidebar-menu-compact,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact {
  /* first level links */
  /* all links */
}
.page-sidebar .page-sidebar-menu.page-sidebar-menu-compact > li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact > li {
  text-align: left;
}
.page-sidebar .page-sidebar-menu.page-sidebar-menu-compact > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact > li > a {
  text-align: left;
  min-height: 50px;
  padding: 15px 15px 15px 15px;
}
.page-sidebar .page-sidebar-menu.page-sidebar-menu-compact > li > a > .title,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact > li > a > .title {
  display: inline-block;
  text-align: left;
  margin-top: 0px;
}
.page-sidebar .page-sidebar-menu.page-sidebar-menu-compact > li > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact > li > a > i {
  font-size: 16px;
  /* $sidebar-menu-link-icon-font-size */
  text-align: left;
}
.page-sidebar .page-sidebar-menu.page-sidebar-menu-compact > li.active > a > .selected,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact > li.active > a > .selected {
  top: 14px;
}
.page-sidebar .page-sidebar-menu.page-sidebar-menu-compact > li > .sub-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact > li > .sub-menu > li > a {
  padding-left: 22px;
}
.page-sidebar .page-sidebar-menu.page-sidebar-menu-compact li > a > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact li > a > .arrow:before {
  margin-top: -1px;
}
.page-sidebar .page-sidebar-menu.page-sidebar-menu-compact li > a > .arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact li > a > .arrow.open:before {
  margin-top: -1px;
}
.page-sidebar .page-sidebar-menu.page-sidebar-menu-compact.page-sidebar-menu-hover-submenu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact.page-sidebar-menu-hover-submenu {
  /* first level links */
}
.page-sidebar .page-sidebar-menu.page-sidebar-menu-compact.page-sidebar-menu-hover-submenu > li > .sub-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact.page-sidebar-menu-hover-submenu > li > .sub-menu > li > a {
  padding-left: 15px;
}

@media (min-width: 992px) {
  /* 992px */
  .page-sidebar {
    width: 195px;
    float: left;
    position: relative;
    margin-right: -100%;
  }
  .page-full-width .page-sidebar {
    display: none !important;
  }
  .page-sidebar.navbar-collapse {
    max-height: none !important;
  }
  .page-sidebar .page-sidebar-menu {
    margin-bottom: 10px;
  }

  .page-sidebar-reversed .page-sidebar {
    float: right;
    margin-right: 0;
    margin-left: -100%;
  }
  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
    margin-left: -195px;
  }
  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar-wrapper {
    position: relative;
    float: right;
  }

  .page-sidebar-fixed .page-sidebar {
    position: fixed !important;
    margin-left: 0;
    top: 68px;
  }
  .page-sidebar-fixed .page-sidebar-menu > li.last {
    margin-bottom: 15px !important;
  }
  .page-sidebar-fixed .page-sidebar-menu .sub-menu {
    height: auto !important;
  }

  /* Sidebar Closed */
  .page-sidebar-closed .page-sidebar {
    width: 54px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed {
    /* sidebar */
    width: 54px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li {
    /* hide opened sub menu */
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.open > .sub-menu,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > .sub-menu {
    display: none !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    width: 249px !important;
    position: relative !important;
    z-index: 10000;
    display: block !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a {
    -webkit-border-radius: 0 4px 0 0;
    -moz-border-radius: 0 4px 0 0;
    -ms-border-radius: 0 4px 0 0;
    -o-border-radius: 0 4px 0 0;
    border-radius: 0 4px 0 0;
    text-align: left;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > i {
    display: inline-block;
    margin-right: 10px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .title {
    display: inline !important;
    padding-left: 20px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .badge {
    display: block !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .selected {
    display: none;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu {
    width: 194px;
    position: absolute;
    z-index: 2000;
    left: 55px;
    margin-top: 0;
    top: 100%;
    display: block !important;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu > li > a {
    padding-left: 15px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu > li > .sub-menu > li > a {
    padding-left: 30px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu > li > .sub-menu > li > .sub-menu > li > a {
    padding-left: 45px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a {
    min-height: 54px;
    padding-left: 11px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a .selected {
    top: 16px;
    right: -2px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .badge,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .title,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .arrow {
    display: none !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-compact.page-sidebar-menu-closed > li > a {
    min-height: 50px;
    padding-right: 11px;
    padding-left: 18px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar {
    margin-left: -54px;
    width: 54px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed {
    /* sidebar */
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > .sub-menu {
    left: auto;
    right: 55px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    margin-left: -195px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a {
    -webkit-border-radius: 4px 0 0 0;
    -moz-border-radius: 4px 0 0 0;
    -ms-border-radius: 4px 0 0 0;
    -o-border-radius: 4px 0 0 0;
    border-radius: 4px 0 0 0;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .title {
    padding-left: 0;
    padding-right: 15px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > i {
    margin-right: 0;
    margin-left: 2px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed > li > a {
    padding-right: 7px;
    padding-left: 11px;
  }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
    width: 195px !important;
    display: block;
    z-index: 10000;
  }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
    width: 195px !important;
  }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .selected {
    display: none !important;
  }
  .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover {
    width: 195px !important;
    display: block;
    z-index: 10000;
    margin-left: -195px !important;
  }
  .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover .page-sidebar-menu {
    width: 195px !important;
  }
  .page-sidebar-closed.page-sidebar-hide .page-sidebar {
    display: none !important;
  }

  /* Default Sidebar Menu With Hoverable Submenu */
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li .sub-menu {
    display: none;
    width: 194px;
    z-index: 2000;
    position: absolute;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li .sub-menu > li > a {
    margin: 3px;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li.active .sub-menu, .page-sidebar-menu.page-sidebar-menu-hover-submenu li.open .sub-menu {
    display: none !important;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li a > .arrow {
    display: none;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow {
    display: block;
    float: right;
    position: absolute;
    right: 0;
    margin-top: -20px;
    background: none;
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 12px double transparent;
    border-bottom: 12px double transparent;
    border-left: 0;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow:after, .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow:before {
    display: none;
  }
  .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow {
    right: auto;
    left: 0;
    border-right: 0;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    display: inline-block !important;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > a > .arrow {
    z-index: 1;
    right: 0px;
    margin-top: -36px;
  }
  .page-fontawesome .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > a > .arrow {
    margin-top: -34px;
  }
  .page-glyphicons .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > a > .arrow {
    margin-top: -38px;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    margin-left: 195px;
    margin-top: -78px;
  }
  .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    margin-left: -194px !important;
  }
  .page-sidebar-closed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    margin-left: 0;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu {
    margin-left: 194px;
    margin-top: -42px !important;
  }
  .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu {
    margin-left: -194px !important;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu > li > a {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Compact Sidebar Menu With Hoverable Submenu */
  .page-sidebar-menu.page-sidebar-menu-compact.page-sidebar-menu-hover-submenu li:hover > a > .arrow {
    margin-top: -20px;
  }
  .page-sidebar-menu.page-sidebar-menu-compact.page-sidebar-menu-hover-submenu > li:hover > a > .arrow {
    margin-top: -20px;
  }
  .page-sidebar-menu.page-sidebar-menu-compact.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    margin-top: -49px;
  }
}
@media (max-width: 991px) {
  /* 991px */
  .page-sidebar {
    border-top: 0 !important;
    margin: 20px;
  }
  .page-sidebar .selected {
    display: none !important;
  }
  .page-sidebar.navbar-collapse {
    max-height: none;
    /* set some max height to have a scrollable menu on mobile devices */
  }
  .page-sidebar.navbar-collapse.collapse {
    display: none !important;
  }
  .page-sidebar.navbar-collapse.in {
    border-top: 0 !important;
    margin: 20px;
    position: relative;
    overflow: hidden !important;
    overflow-y: auto !important;
    display: block !important;
  }
  .page-sidebar.navbar-collapse.navbar-no-scroll {
    max-height: none !important;
  }
  .page-sidebar .mega-menu-responsive-content {
    padding: 10px 18px 10px 45px;
  }

  .page-full-width .page-sidebar-menu {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* 768px & 991px */
  .page-sidebar .btn-navbar.collapsed .arrow {
    display: none;
  }
  .page-sidebar .btn-navbar .arrow {
    position: absolute;
    right: 25px;
    width: 0;
    height: 0;
    top: 50px;
    border-bottom: 15px solid #5f646b;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
}
@media (max-width: 480px) {
  /* 480px */
  /* Page sidebar */
  .page-sidebar,
  .page-sidebar.in {
    margin: 10px 10px 10px 10px !important;
  }
  .page-header-fixed.page-header-fixed-mobile .page-sidebar, .page-header-fixed.page-header-fixed-mobile
  .page-sidebar.in {
    margin-top: 10px !important;
  }
}
/***
Page content
***/
/* Page title */
.page-title {
  padding: 0px;
  font-size: 26px;
  letter-spacing: -1px;
  line-height: 26px;
  display: block;
  color: #666;
  margin: 0px 0px 20px 0px;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  /* subtitle */
}
.page-title small {
  font-size: 13px;
  letter-spacing: 0px;
  font-weight: 300;
  color: #888;
}
.page-container-bg-solid .page-title {
  color: #666;
}
.page-container-bg-solid .page-title small {
  color: #666;
}

/* Page breadcrumb */
.page-bar {
  padding: 0px;
  background-color: #ffffff;
  margin-bottom: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.page-bar:before, .page-bar:after {
  content: " ";
  display: table;
}
.page-bar:after {
  clear: both;
}
.page-bar .page-breadcrumb {
  display: inline-block;
  float: left;
  padding: 10px 6px;
  margin: 0;
  list-style: none;
}
.page-bar .page-breadcrumb > li {
  display: inline-block;
}
.ie8 .page-bar .page-breadcrumb > li {
  margin-right: 1px;
}
.page-bar .page-breadcrumb > li > a,
.page-bar .page-breadcrumb > li > span {
  color: #888;
  font-size: 13px;
  text-shadow: none;
}
.page-bar .page-breadcrumb > li > i {
  color: #aaa;
  font-size: 14px;
  text-shadow: none;
}
.page-bar .page-breadcrumb > li > i[class^="icon-"],
.page-bar .page-breadcrumb > li > i[class*="icon-"] {
  color: #8c8c8c;
}
.page-bar .page-toolbar {
  display: inline-block;
  float: right;
}
.page-bar .page-toolbar .btn-fit-height {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  padding-top: 9px;
  padding-bottom: 9px;
}
.page-md .page-bar .page-toolbar .btn-fit-height {
  padding-top: 11px;
  padding-bottom: 10px;
  box-shadow: none !important;
}
.page-bar .page-toolbar .btn.btn-default {
  border-color: #eee;
  color: #999;
}
.page-bar .page-toolbar .btn.btn-default > i {
  color: #999;
}
.page-bar .page-toolbar .btn.btn-sm {
  margin-top: 0px;
}
.page-bar .page-toolbar .btn-dashboard-daterange {
  padding: 8px 16px 8px 16px;
}
.page-bar .page-toolbar .btn-dashboard-daterange > i[class^="icon-"] {
  position: relative;
  font-size: 20px;
  opacity: 0.8 ;
  filter: alpha(opacity=80) ;
  top: 2px;
}
.page-bar .page-toolbar .btn-dashboard-daterange > i.fa-angle-down {
  font-size: 16px;
  opacity: 0.8 ;
  filter: alpha(opacity=80) ;
}

/* Page content */
.page-content {
  margin-top: 0px;
  padding: 0px;
  background-color: #fff;
}
.page-container-bg-solid .page-content {
  background: #FFFFFF;
}
.page-full-width .page-content {
  margin-left: 0px !important;
}

@media (min-width: 992px) {
  /* 992px */
  /* Page content */
  .page-content-wrapper {
    float: left;
    width: 100%;
  }
  .page-content-wrapper .page-content {
    margin-left: 195px;
    margin-top: 0px;
    min-height: 600px;
    padding: 25px 20px 10px 20px;
  }
  .page-content-wrapper .page-content.no-min-height {
    min-height: auto;
  }
  .page-sidebar-fixed.page-sidebar-hover-on .page-content-wrapper .page-content {
    margin-left: 54px;
  }
  .page-sidebar-reversed .page-content-wrapper .page-content {
    margin-left: 0 !important;
    margin-right: 195px !important;
  }
  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-hover-on .page-content-wrapper .page-content {
    margin-left: 0;
    margin-right: 54px;
  }
  .page-sidebar-reversed.page-sidebar-closed .page-content-wrapper .page-content {
    margin-left: 0 !important;
    margin-right: 54px !important;
  }
  .page-sidebar-closed .page-content-wrapper .page-content {
    margin-left: 54px !important;
  }
  .page-sidebar-closed.page-sidebar-hide .page-content-wrapper .page-content {
    margin-left: 0 !important;
  }
  .page-sidebar-closed.page-sidebar-reversed.page-sidebar-hide .page-content-wrapper .page-content {
    margin-right: 0 !important;
  }
  .page-full-width .page-content-wrapper .page-content {
    margin-left: 0px !important;
  }
}
@media (max-width: 991px) {
  /* 991px */
  /* Boxed page container  */
  .page-boxed > .container {
    width: 100%;
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Page content */
  .page-content-wrapper .page-content {
    margin: 0px !important;
    padding: 20px !important;
    min-height: 280px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /*  768px & 991px */
  /* Boxed page container */
  .page-boxed > .container {
    margin: auto !important;
  }
}
@media (max-width: 767px) {
  /* 767px */
  /* Page content */
  .page-content-wrapper .page-content {
    padding: 20px 10px 10px 10px !important;
    overflow: hidden;
    /* Page title */
  }
  .page-content-wrapper .page-content .page-title {
    margin-bottom: 15px;
    font-size: 20px;
  }
  .page-content-wrapper .page-content .page-title small {
    font-size: 13px;
    padding-top: 3px;
  }
}
@media (max-width: 480px) {
  /* 480px */
  /* Dashboard date range panel */
  .page-content-wrapper .page-content .page-title small {
    display: block;
    clear: both;
  }
}
/***
Page footer
***/
.page-footer {
  padding: 8px 20px 5px 20px;
  font-size: 12px;
  height: 33px;
}
.page-footer:before, .page-footer:after {
  content: " ";
  display: table;
}
.page-footer:after {
  clear: both;
}
.page-footer .page-footer-inner {
  float: left;
  display: inline-block;
}
.page-footer .page-footer-tools {
  float: right;
  display: inline-block;
}
.page-footer .page-footer-tools .go-top {
  display: block;
  text-decoration: none;
  cursor: pointer;
  margin-top: -2px;
  margin-right: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  padding: 0px 6px 0px 6px;
}
.page-footer .page-footer-tools .go-top i {
  font-size: 22px;
  margin-bottom: 5px;
}
.page-footer-fixed.page-footer-fixed-mobile .page-footer {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10000;
  bottom: 0;
}
.page-footer-fixed.page-footer-fixed-mobile.page-sidebar-fixed .page-footer {
  margin-left: 0 !important;
}

@media (min-width: 992px) {
  /* 992px */
  /* Default footer */
  .page-footer {
    clear: left;
  }

  /* Fixed footer */
  .page-footer-fixed .page-footer {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10000;
    bottom: 0;
  }

  /* Footer with footer sidebar */
  .page-sidebar-fixed.page-sidebar-closed .page-footer {
    margin-left: 54px;
  }
  .page-sidebar-fixed.page-footer-fixed .page-footer {
    margin-left: 0 !important;
  }

  /* Fixed Sidebar */
  .page-sidebar-fixed .page-footer {
    margin-left: 195px;
    padding: 8px 20px 5px 20px;
  }

  /* Boxed page */
  .page-boxed .page-footer {
    padding: 8px 0 5px 0;
  }
  .page-boxed.page-sidebar-fixed .page-footer {
    padding-right: 20px;
    padding-left: 20px;
  }

  /* Page sidebar reversed */
  .page-sidebar-reversed.page-sidebar-fixed .page-footer {
    margin-left: 0;
    margin-right: 195px;
    padding: 8px 20px 5px 20px;
  }
  .page-sidebar-reversed.page-sidebar-fixed.page-footer-fixed .page-footer {
    margin-left: 0;
    margin-right: 0;
  }
  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-closed .page-footer {
    margin-right: 54px;
  }
}
@media (max-width: 991px) {
  /* 991px */
  /* Boxed Layout */
  .page-footer {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 767px) {
  /* 767px */
  /* Default footer & boxed footer */
  .page-footer,
  .page-boxed .page-footer {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Fixed footer */
  .page-footer-fixed .page-footer .container {
    padding-left: 0;
    padding-right: 0;
  }
}
/* Scroll Top Top */
.scroll-to-top {
  display: inline-block;
  padding: 2px;
  text-align: center;
  position: fixed;
  z-index: 9990;
  bottom: 10px;
  display: none;
  right: 10px;
}
.scroll-to-top > i {
  display: inline-block;
  color: #687991;
  font-size: 32px;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}
.scroll-to-top:hover {
  cursor: pointer;
}
.scroll-to-top:hover > i {
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}

@media (min-width: 992px) {
  /* 992px */
  .scroll-to-top {
    right: 10px;
  }
}
@media (max-width: 991px) {
  /* 991px */
  .scroll-to-top {
    right: 10px;
  }
  .scroll-to-top > i {
    font-size: 28px;
  }
}
/***
Theme Panel
***/
.theme-panel {
  width: 400px;
  margin-top: -15px;
  margin-right: 0px;
  z-index: 100;
  float: right;
  position: relative;
}
.theme-panel > .toggler {
  top: 5px;
  right: 1px;
  height: 40px;
  width: 40px;
  border-radius: 50% !important;
  cursor: pointer;
  position: absolute;
  text-align: center;
  background-color: #fff;
}
.theme-panel > .toggler > i {
  position: relative;
  top: 12px;
  font-size: 20px;
  color: #9fb3ca;
}
.theme-panel > .toggler:hover {
  background: #ACB5C3;
}
.theme-panel > .toggler:hover > i {
  color: #fff;
}
.theme-panel > .toggler-close {
  display: none;
  top: 5px;
  right: 1px;
  z-index: 101;
  cursor: pointer;
  position: absolute;
}
.theme-panel > .toggler-close > i {
  position: relative;
  top: 12px;
  right: 12px;
  font-size: 20px;
  color: #f2f2f2;
}
.theme-panel > .toggler-close:hover {
  opacity: 0.8 ;
  filter: alpha(opacity=80) ;
}
.theme-panel > .theme-options {
  box-shadow: 5px 5px rgba(63, 77, 86, 0.1);
  top: 4px;
  right: 0;
  display: none;
  position: absolute;
  z-index: 100;
  background: #3f4d56;
  border: 1px solid #39454d;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.theme-panel > .theme-options > .theme-option {
  color: #eee;
  padding: 11px;
  border-top: 1px solid #46545f;
  margin-top: 0px;
  margin-bottom: 0px;
}
.theme-panel > .theme-options > .theme-option > span {
  text-transform: uppercase;
  display: inline-block;
  width: 115px;
  font-size: 13px;
  font-weight: 300;
}
.theme-panel > .theme-options > .theme-option > select.form-control {
  display: inline;
  width: 100px;
  padding: 2px;
  text-transform: lowercase;
}
.theme-panel > .theme-options > .theme-option.theme-colors {
  border-top: 0;
}
.theme-panel > .theme-options > .theme-option.theme-colors > span {
  display: block;
  width: auto;
}
.theme-panel > .theme-options > .theme-option.theme-colors > ul {
  list-style: none;
  padding: 0;
  display: block;
  margin-bottom: 10px !important;
  margin-top: 15px;
}
.theme-panel > .theme-options > .theme-option.theme-colors > ul > li {
  width: 46px;
  height: 45px;
  margin: 0 4px;
  cursor: pointer;
  list-style: none;
  float: left;
  border: solid 1px #707070;
  /* theme colors */
}
.theme-panel > .theme-options > .theme-option.theme-colors > ul > li:first-child {
  margin-left: 0;
}
.theme-panel > .theme-options > .theme-option.theme-colors > ul > li:hover, .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.current {
  border: solid 2px #d64635;
}
.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-default {
  background: #2b3643;
}
.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-dark {
  background: #333438;
}
.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-blue {
  background: #26344B;
}
.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-grey {
  background: #4D5B69;
}
.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-light {
  background: #f5f5f5;
}

/***     
Page Loading    
***/
.page-on-load {
  background: #fefefe;
}
.page-on-load .page-header,
.page-on-load .page-container,
.page-on-load .page-footer,
.page-on-load > .clearfix {
  display: none;
  transition: all 2s;
}
